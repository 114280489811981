import React, {useState,useEffect}  from 'react'
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import {BsHeartFill} from 'react-icons/bs'
export default function ButtonPostes(props){


    //Initialisation des variables
    const [postes,setPostes] = useState([]);



    useEffect(()=>{
        fetchData();
    },[]);

    //appel API 
    function fetchData(){
        const headers = {'Accept':'application/json'};
        let localData = JSON.parse(localStorage.getItem("localData"))
        fetch(localData.endpoint+"/api/postes?cle="+localData.cle,{headers})
        .then(response=> 
            response.json()
        )
        .then(data => {
            setPostes(data)
            props.setPostes(data)
        });
    }

    function onClick(event){
        props.onPosteClick(event.target.value);
    }


    //création des button pour tous les postes existent
    const allPostes = postes.map( poste =>
        <Button value={poste.pos_libelle} key={poste.pos_no_pk} onClick={onClick} variant="secondary">{poste.pos_libelle}</Button>
    )
    
    return (
        <ButtonGroup class="centrage" aria-label="Basic example">
            <Button value="Favori" key="Favori" variant="secondary" onClick={onClick}>Favori</Button>
            <Button value="Activités Récentes" key="recente" variant="secondary" onClick={onClick}>Activités Récentes</Button>
            {allPostes}
        </ButtonGroup>
    )
        
    
}
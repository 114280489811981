import React, { useState } from 'react'
import './App.css';
import ButtonPostes from './ButtonPostes'
import Header from './common/Header';
import ListActivity from './ListActivity';
import UserActivities from './UserActivities'
import { Alert } from 'react-bootstrap'

function App() {

  //Initialisation des variables
  const [poste, setPoste] = useState(localStorage.getItem("paramChoice"));
  const [postesList, setPostesList] = useState([]);
  const [recentActivities, setRecentActivities] = useState([]);
  let localData = JSON.parse(localStorage.getItem("localData"))



  //Vérifier si les paramètres API existent 
  if (localData == null) {
    return (
      <>
        <Header posteList={postesList} />
        <Alert variant="danger">
          Veuillez remplir les paramètres
        </Alert>
      </>);
  } else {
    if (localData.endpoint == null && localData.cle == null) {
      return (
        <>
          <Header posteList={postesList} />
          <Alert variant="danger">
            Veuillez remplir les paramètres
          </Alert>
        </>
      );
    } else {
      return (
        <>
          <Header posteList={postesList} />
          <div class="centrage">
            <ButtonPostes onPosteClick={setPoste} setPostes={setPostesList}/>
          </div>
          <div class="test1">
            <ListActivity poste={poste} recentActivity={recentActivities} />
          </div>
          <div class="test2">
            <UserActivities setRecentActivity={setRecentActivities} />
          </div>

        </>
      );
    }
  }



}

export default App;

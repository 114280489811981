import React, {useState,useEffect}  from 'react'
import moment from 'moment'
import ActivityCard from './ActivityCard'
import {Alert,Container, Row, Col,} from 'react-bootstrap'
import './index.css'

export default function ListActivity(props){

    useEffect(()=>{

        fetchData();
        verifyLocalStorage();

    },[]);

    //Initialisation des variables
    const [activities,setActivities] = useState([]);


    function notifyMe() {
        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
          alert("This browser does not support desktop notification");
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
          // If it's okay let's create a notification
          var notification = new Notification("Temps ajouté!");
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== "denied") {
          Notification.requestPermission().then(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
              var notification = new Notification("Temps Ajouté!");
            }
          });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
      }

    //Vérifier que les données dans les localStorage existent sinon il les créés
    function verifyLocalStorage(){
        if(localStorage.getItem("localLog")===null){
            let array = {"user":"","favori":[]}
            array = JSON.stringify(array);
            localStorage.setItem("localLog", array)
        }

        if(localStorage.getItem("localRecent")===null){
            let arrayRecent = {"activityRecent":[]}
            arrayRecent = JSON.stringify(arrayRecent);
            localStorage.setItem("localRecent", arrayRecent)
        }
    }

    //Rajouter en Favori dans le localStorage
    function setFavoriCookie(favori){
        let localLog = JSON.parse(localStorage.getItem("localLog"))
        if(!localLog.favori.includes(favori)){
            localLog.favori.push(favori)
            localStorage.setItem("localLog",JSON.stringify(localLog))
        }
        else{
            let index = localLog.favori.indexOf(favori);
            if(index > -1){
                localLog.favori.splice(index,1)
                localStorage.setItem("localLog",JSON.stringify(localLog))
            }
        }
    }

    //appel API
    function fetchData(){
        const headers = {'Accept':'application/json'};
        let localData = JSON.parse(localStorage.getItem("localData"))
        fetch(localData.endpoint+"/api/activities?cle="+localData.cle,{headers})
        .then(response=>
            response.json()
    )
        .then( data =>
            setActivities(data)
        );
    }

    //Créer le pointage
    function createActivity(infos){
        let localData = JSON.parse(localStorage.getItem("localData"))
        let datenow = moment().format("YYYY-MM-DD HH:mm:ss");
            fetch(localData.endpoint+'/api/create',{
                method:'POST',
                body: new URLSearchParams({
                    'cle':localData.cle,
                    'poste': infos.poste,
                    'contexte':infos.contexte,
                    'dateexec':datenow,
                    'tempasse':localData.time,
                    'nom':localData.user,
                    'manuel':'non'
                })
            }).then(response=>
                response.json()
            ).then(data=>
                setRecentActivities(infos)
            )
    }




    //Set les activités récentes
    function setRecentActivities(infos){
        notifyMe()
        let object = {"act_contexte":infos.allActivity.act_contexte,"act_no_pk":infos.allActivity.act_no_pk,"act_pos_no":infos.allActivity.act_pos_no,"act_poste":infos.allActivity.act_poste,"pos_libelle":infos.allActivity.pos_libelle,"pos_no_pk":infos.allActivity.pos_no_pk}

        let localRecent = JSON.parse(localStorage.getItem("localRecent"))

        if(localRecent.activityRecent.length>0){
            for (let index = 0; index < localRecent.activityRecent.length; index++) {

                if(localRecent.activityRecent[index].act_contexte !== object.act_contexte){

                    if(localRecent.activityRecent.length===5){
                        localRecent.activityRecent.shift()
                        localRecent.activityRecent.push(object)
                        localStorage.setItem("localRecent", JSON.stringify(localRecent))
                        return

                    }else{
                        localRecent.activityRecent.push(object)
                        localStorage.setItem("localRecent", JSON.stringify(localRecent))
                        return
                    }
                }
            }
        }else{
            localRecent.activityRecent.push(object)
            localStorage.setItem("localRecent", JSON.stringify(localRecent))
            return
        }
    }
    //Affichages des activités selon l'onglet
    let allActivities = "\nAucune Activité / Error";
    if(props.poste === "Activités Récentes"){
        let recentActivities = JSON.parse(localStorage.getItem("localRecent"))
        if(recentActivities.activityRecent.length > 0){
            allActivities = recentActivities.activityRecent.map(filteredActivity =>
                <ActivityCard onglet={props.poste} setFavori={setFavoriCookie} create={createActivity} allActivity={filteredActivity} poste={filteredActivity.act_poste} contexte={filteredActivity.act_contexte} key={filteredActivity.act_no_pk} no_pk={filteredActivity.act_no_pk} variant="secondary">{filteredActivity.act_contexte}</ActivityCard>
            )
        }else{
            allActivities = <Alert variant="primary">Aucune activité récente</Alert>;
        }

    }else if(props.poste === 'Favori'){
        let localLog = JSON.parse(localStorage.getItem("localLog"))
        if(localLog !== null){
            if(localLog.favori.length>0 ){
                allActivities = activities.filter(activity => localLog.favori.includes(activity.act_no_pk)).map(filteredActivity=>
                    <ActivityCard onglet={props.poste} setFavori={setFavoriCookie} allActivity={filteredActivity} create={createActivity} poste={filteredActivity.act_poste} contexte={filteredActivity.act_contexte} key={filteredActivity.act_no_pk} no_pk={filteredActivity.act_no_pk} variant="secondary">{filteredActivity.act_contexte}</ActivityCard> )
            }else{
                allActivities = <Alert variant="primary">Aucune activité favorite</Alert>;
            }
        }else{
            allActivities = <Alert variant="primary">Aucune activité favorite</Alert>;
        }

    }
    else if (props.poste !== 'Favori'){
        allActivities = activities.filter(activity => activity.act_poste === props.poste).map(filteredActivity =>
            <ActivityCard onglet={props.poste} setFavori={setFavoriCookie} allActivity={filteredActivity} create={createActivity} poste={filteredActivity.act_poste} contexte={filteredActivity.act_contexte} key={filteredActivity.act_no_pk} no_pk={filteredActivity.act_no_pk} variant="secondary">{filteredActivity.act_contexte}</ActivityCard>
        )
    }



    return(
        <>

            <Container>
                <Row >
                    <Col>
                        {allActivities}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

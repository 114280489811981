import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Modal } from 'react-bootstrap'

export default function ButtonParam(props) {

  //Initialisation des variables
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [endpoint, setEndpoint] = useState('')
  const [user, setUser] = useState('TEST')
  const [cle, setCle] = useState('')
  const [time, setTime] = useState('0.5')
  const [erreur, setErreur] = useState("")


  useEffect(() => {
    setParam()
    getParamChoiceLocalStorage()
  }, [])

  //Initialisation de la modal avec les paramètres récupérés dans le localStorage
  function setParam() {
    let localData = JSON.parse(localStorage.getItem("localData"))
    if (localData !== null) {
      if (localData.cle !== "" && localData.endpoint !== "" && localData.user !== "" && localData.time !=="") {
        setCle(localData.cle) 
        setEndpoint(localData.endpoint)
        setTime(localData.time)
        setUser(localData.user)
      }
    }
  }

  //Sauvegarder dans le LocalStorage les paramètres pour les appels API
  function SaveChanges() {
    if (user && cle && time && endpoint) {
      setErreur("")
      let changes = { "user": user, "cle": cle, "time": time, "endpoint": endpoint }
      changes = JSON.stringify(changes)
      localStorage.setItem("localData", changes)
      setShow(false);
      document.location.reload()
    } else {
      setErreur("Veuillez remplir tous les champs")
    }
  }


  function setTextEndpoint(event) {
    setEndpoint(event.target.value)
  }

  function setTextUser(event) {
    setUser(event.target.value)
  }

  function setTextCle(event) {
    setCle(event.target.value)
  }

  function setTextTime(event) {
    setTime(event.target.value)
  }
  let optionFavori  = props.posteListParam.map(filteredOption =>
    filteredOption.pos_libelle === localStorage.getItem("paramChoice") ? <option value={filteredOption.pos_libelle} selected>{filteredOption.pos_libelle}</option> : <option value={filteredOption.pos_libelle}>{filteredOption.pos_libelle}</option>
    
  )
  //console.log(optionFavori)
    function onChangeFavori(e){
      localStorage.setItem("paramChoice", e.target.value)
    }

    function getParamChoiceLocalStorage(){
      if(localStorage.getItem("paramChoice")===null){
        localStorage.setItem("paramChoice", "Favori")
      }
    }
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Paramètres
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Paramètres</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table>
            <tr>
              <td>
                Endpoint:
              </td>
              <td>
                <input type="text" value={endpoint} onChange={setTextEndpoint} />
              </td>
            </tr>
            <tr>
              <td>
                User:
              </td>
              <td>
                <input type="text" value={user} onChange={setTextUser} />
              </td>
            </tr>
            <tr>
              <td>
                Cle:
              </td>
              <td>
                <input type="text" value={cle} onChange={setTextCle} />
              </td>
            </tr>
            <tr>
              <td>
                Temps Passé:
              </td>
              <td>
                <input type="text" value={time} onChange={setTextTime} />
              </td>
            </tr>
            <tr>
              <td>
                Onglet par défault
              </td>
              <td>
                <select onChange={onChangeFavori}>
                  <option value ="Favori">Favori</option>
                  <option value="Activités Récentes">Activités Recentes</option>
                  {optionFavori}
                </select>
              </td>
            </tr>
            

          </table>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <p>{erreur}</p>
          </div>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={SaveChanges}>
            Save Changes
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );

}
import React, {useState,useEffect}  from 'react'
import Table from 'react-bootstrap/Table'
import './index.css'
export default function UserActivities(props){
    //useState
    const [activities,setActivities] = useState([]);
    useEffect(()=>{
        fetchData();
        
    },[]);

    //Récupérer les activités 
    function fetchData(){
        let localData = JSON.parse(localStorage.getItem("localData"))
        const headers = {'Accept':'application/json'};
        fetch(localData.endpoint+"/api/userdayactivities?cle="+localData.cle+"&nom="+localData.user,{headers})
        .then(response=> 
            response.json())
        .then(data => 
            setActivities(data)
        );
    }

    //Création du body du tableau
    const allActivities = activities.map( activity =>
        <tr>
            <td>{activity.log_no_pk}</td>
            <td>{activity.log_contexte}</td>
            <td>{activity.log_poste}</td>
            <td>{activity.log_nb_heures}</td>
            <td>{activity.log_nom}</td>
            <td>{activity.log_dtsrvexec.substring(10)}</td>
        </tr>
    )



        return (
            <Table  striped bordered hover variant="dark">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Contexte</th>
                        <th>Poste</th>
                        <th>Nombre d'Heures</th>
                        <th>Nom</th>
                        <th>heure de pointage</th>
                    </tr>
                </thead>
                <tbody>
                    {allActivities}
                </tbody>
            </Table>
        )
}

import './style.css'
import Logo from './Logo'
import ButtonParam from './ButtonParam'
import React, { useState, useEffect } from 'react'

export default function Header(props){
    const [postesListsParam, setPostesList] = useState([]);

    useEffect(()=>{
        setPostesList(props.posteList)
    }, [props.posteList])
    
    return(
        <header className="head">
            <div>
                <Logo/>
            </div>
            <h1>
                TaskTimeLogger
            </h1>
            <div class="gauche">
                <ButtonParam posteListParam={postesListsParam}/>
            </div>
        </header>
    )
}
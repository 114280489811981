import React, { useState, useEffect } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import './style.css'
import { BsHeart, BsHeartFill } from 'react-icons/bs'


export default function ActivityCard(props) {

    //Initialisation des variables
    const [stateFavorite, setStateFavorite] = useState(0);
    const [favorite, setFavorite] = useState("inline");
    const [favoriteFill, setFavoriteFill] = useState("none");

    useEffect(() => {
        FavoriOngletInit()
    }, []);


    function create() {
        props.create(props)
    }

    //Click sur le bouton Favori

    function onclick() {
        //console.log(props.no_pk)
        props.setFavori(props.no_pk)
        if (stateFavorite === 1) {
            setStateFavorite(0)
            setFavorite("inline")
            setFavoriteFill("none")

        } else {
            setStateFavorite(1)
            setFavorite("none")
            setFavoriteFill("inline")
        }
        if (props.onglet === "Favori") {
            document.location.reload()
        }
    }

    //Vérifie qu'on est dans l'onglet favori
    function FavoriOngletInit() {
        if (props.onglet === "Favori") {
            setFavorite("none")
            setFavoriteFill("inline")
        }
    }

    return (
        <>
        <div className="card">
            <ButtonGroup>
                <Button onClick={create}>{props.contexte}</Button>
                <Button onClick={onclick}><BsHeart style={{ display: favorite }} /> <BsHeartFill style={{ display: favoriteFill }} /></Button>
            </ButtonGroup>
        </div>
        </>
    )
}